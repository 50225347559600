body {
  background: url(./images/wavegrid.png);
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  body {
    background: url(./images/wavegrid@2X.png);
    background-size: 100px 100px;
  }
}

.ui.segment,
.ui.menu {
  box-shadow: none;
}

.ui.bottom.attached.message {
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset, 0 0 1px 0 rgba(34, 36, 38, 0.15);
}

.ui.card,
.ui.cards > .card {
  box-shadow: 0 0 0 1px #d4d4d5;
}

.ui.table th {
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

header .menu-container {
  margin: 1rem;
}

header .info-container {
  background: #f0f0f0;
}

.release-info-popup .details {
  vertical-align: top;
  padding-left: 1rem;
}

.ui.segment > h1.header {
  margin-top: 0px;
}

nav.ui.secondary.pointing.menu .item {
  border-bottom-width: 0.3rem;
}

nav.ui.secondary.menu .item.purple.active,
nav.ui.secondary.menu .item.purple:hover {
  color: #fff !important;
  background-color: #b413ec;
}

nav.ui.secondary.menu .item.green.active,
nav.ui.secondary.menu .item.green:hover {
  color: #fff !important;
  background-color: #016936;
}

nav.ui.secondary.menu .item.orange.active,
nav.ui.secondary.menu .item.orange:hover {
  color: #fff !important;
  background-color: #fe9a76;
}

nav.ui.secondary.menu .item.teal.active,
nav.ui.secondary.menu .item.teal:hover {
  color: #fff !important;
  background-color: #008080;
}


nav.ui.secondary.menu .item.pink.active,
nav.ui.secondary.menu .item.pink:hover {
  color: #fff !important;
  background-color: #FF1493;
}

nav.ui.secondary.menu .item {
  background-color: rgba(210, 210, 210, 0.4);
}

nav.ui.secondary.menu a.item:hover {
  opacity: 0.7;
}

nav.ui.secondary.menu .item img.avatar,
.ui.table td img.avatar {
  margin: -0.5rem 0.5rem -0.5rem 0;
}

.ui.pointing.secondary.attached.menu {
  margin-left: -1px;
}

.ui.secondary.pointing.attached.menu .item {
  margin-bottom: 0;
}

span.clickable {
  cursor: pointer;
  color: #666;
}

nav.ui.secondary.menu .item.userMenu {
  background: none;
}

nav.ui.secondary.menu .item .companyLogo {
  margin: -0.6rem 0;
  max-height: 2.2rem;
}

.icon.large.selection {
  margin-top: -0.3rem;
  padding-right: 0.5rem;
}

.ui.label.platform {
  margin-bottom: 0.3rem;
}

#root > main {
  margin: 1rem;
}

.ui.menu.pagination,
.ui.attached.menu,
.ui.attached.header {
  background: #f8f8f8;
}

.ui.table tr.divider {
  font-weight: bold;
  background: #f8f8f8;
}

.ui.table.compact tr.divider td {
  padding: 0.5rem 1rem;
}

.ui.progress:last-child {
  margin: 0;
}

.ui.segment h3 {
  margin-top: 0;
}

.ui.logs .INFO {
  color: #000;
}

.ui.logs .DEBUG {
  color: #008080;
}

.ui.logs .WARN {
  color: #fe9a76;
}

.ui.logs .ERROR {
  color: #008080;
}

.ui.top.attached.red,
.ui.modal .red.header {
  border-top: 2px solid #b03060;
}

.ui.top.attached.purple,
.ui.modal .purple.header,
.ui.table.purple {
  border-top: 2px solid #b413ec;
}

.ui.top.attached.orange,
.ui.modal .orange.header {
  border-top: 2px solid #fe9a76;
}

.ui.top.attached.teal,
.ui.modal .teal.header {
  border-top: 2px solid #008080;
}

.ui.top.attached.green,
.ui.modal .green.header {
  border-top: 2px solid #016936;
}

.ui.top.attached.olive,
.ui.modal .olive.header {
  border-top: 2px solid #32cd32;
}

#root .Toastify__toast {
  border-radius: 0.3rem;
}

#root .Toastify__toast--default {
  background: #fff;
  color: #333;
}

#root .Toastify__toast--info {
  background: #3498db;
}

#root .Toastify__toast--success {
  background: #016936;
}

#root .Toastify__toast--warning {
  background: #f1c40f;
}

#root .Toastify__toast--error {
  background: #b03060;
}

.ui.table.files i.play {
  color: #555;
  font-size: smaller;
  display: block;
  float: left;
  visibility: hidden;
}

.ui.table.files tr:hover i.play {
  visibility: visible;
}

.ui.table.files tr:hover td.play {
  cursor: pointer;
}

.ui.definition.table tr td:not(:first-child) {
  border-left: 1px solid rgba(34, 36, 38, 0.1);
}

div.contributors {
  display: inline-block;
}

a.contributor {
  display: inline-block;
  white-space: nowrap;
}

a.contributor:not(:last-child) {
  margin-right: .5em;
}

a.contributor:not(:last-child)::after {
  content: ",";
}

.ineditor a.contributor {
  border-bottom: 1px dotted;
}

.ineditor,
.ineditor div {
  display: inline-block;
  position: relative;
  top: 1px;
}

.ineditor i.icon {
  margin-left: 0.3em;
  visibility: hidden;
  color: #666;
}

.contributors + i.icon {
  margin-left: -.3em;
}

.imageInfo {
  text-align: center;
  font-size: smaller;
}

.imageInfo span {
  margin: 0 .2em;
}

td:hover > .ineditor i.icon,
.ineditor:hover i.icon {
  visibility: visible;
}

.ineditor {
  position: relative;
  word-wrap: pre;
}

.ineditor div {
  position: relative;
  white-space: nowrap;
  margin: -0.1em;
}

.ineditor i.icon {
  right: -2em;
  bottom: .2em;
  color: #0E6EB8;
  position: absolute;
  visibility: hidden;
  display: block;
}

.ineditor input,
.ineditor span {
  margin: 0;
  padding: 0.1em;
  border: none;
  background-color: transparent;
}

.ineditor span {
  display: inline-block;
  visibility: hidden;
  white-space: pre;
}

.ineditor input {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  outline: none;
  border-bottom: 1px dotted;
}

.ineditor input:focus {
  background-color: #f0f0f0;
  outline: none;
}

.ineditor.modified input {
  color: #016936;
  border-color: #016936;
}

.fileUpload:focus {
  outline: none;
}

.distributions {
  margin: -0.3em;
}

.distributions > .ui.label.small {
  margin: 0.3em;
}

/*
@red            : #B03060;
@orange         : #FE9A76;
@yellow         : #FFD700;
@olive          : #32CD32;
@green          : #016936;
@teal           : #008080;
@blue           : #0E6EB8;
@violet         : #EE82EE;
@purple         : #B413EC;
@pink           : #FF1493;
@brown          : #A52A2A;
@grey           : #A0A0A0;
*/
